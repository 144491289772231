import axios from "axios";
import { ethers } from "ethers";

class Chain {
  constructor() {
    this.provider = null;

    this.erc20_ABI = [
      "function decimals() view returns(uint8)",
      "function totalSupply() view returns(uint256)",
      "function symbol() view returns(string memory)",
      "function name() view returns(string memory)",
      "function uniswapV2Pair() view returns(address)",
      "function balanceOf(address owner) view returns (uint256)",
    ];
    this.pcs_pair_ABI = [
      "function decimals() view returns(uint8)",
      "function totalSupply() view returns(uint256)",
      "function symbol() view returns(string memory)",
      "function token0() view returns(address)",
      "function token1() view returns(address)",
      "function getReserves() view returns(uint112, uint112, uint32)",
      "function balanceOf(address owner) view returns (uint256)",
    ];
  }

  getProvider() {
    if (this.provider) {
      return this.provider;
    }
    const provider = new ethers.providers.JsonRpcProvider("https://bsc-dataseed2.defibit.io/");
    this.provider = provider;
    return provider;
  }

  async getStats(contractAddress) {
    try {
      const symbol = await this.getSymbol(contractAddress);
      const decimals = await this.getDecimals(contractAddress);
      const maxSupply = await this.getTotalSupply(contractAddress, decimals);
      const burnt = await this.getBurnt(contractAddress, decimals);
      const totalSupply = maxSupply - burnt;
      const burntPercent = (1.0 * burnt) / maxSupply;
      const liquidityPairAddress = await this.getLiquidityPairAddress(contractAddress);
      const [tokenPriceBnb, liquidityTotalBnb] = await this.getTokenPriceBnb(liquidityPairAddress, decimals);
      const bnbPriceUsd = await this.getBnbPriceUsd();
      const liquidityTotalUsd = liquidityTotalBnb * bnbPriceUsd;
      const tokenPriceUsd = tokenPriceBnb * bnbPriceUsd;
      const marketCapUsd = totalSupply * tokenPriceUsd;
      const percentLiquidityBacking = liquidityTotalUsd / marketCapUsd;
      return [symbol, totalSupply, burntPercent || 0, liquidityPairAddress, tokenPriceUsd, marketCapUsd, liquidityTotalUsd, percentLiquidityBacking];
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async getMarketCap(contractAddress) {
    try {
      const decimals = await this.getDecimals(contractAddress);
      const liquidityPairAddress = await this.getLiquidityPairAddress(contractAddress);
      const maxSupply = await this.getTotalSupply(contractAddress, decimals);
      const burnt = await this.getBurnt(contractAddress, decimals);
      const totalSupply = maxSupply - burnt;
      const [tokenPriceBnb, liquidityTotalBnb] = await this.getTokenPriceBnb(liquidityPairAddress, decimals);
      return totalSupply * tokenPriceBnb * (await this.getBnbPriceUsd());
    } catch (e) {
      return null;
    }
  }

  async getMarketCapBnb(contractAddress) {
    try {
      const decimals = await this.getDecimals(contractAddress);
      const liquidityPairAddress = await this.getLiquidityPairAddress(contractAddress);
      const maxSupply = await this.getTotalSupply(contractAddress, decimals);
      const burnt = await this.getBurnt(contractAddress, decimals);
      const totalSupply = maxSupply - burnt;
      const [tokenPriceBnb, liquidityTotalBnb] = await this.getTokenPriceBnb(liquidityPairAddress, decimals);
      return totalSupply * tokenPriceBnb;
    } catch (e) {
      return null;
    }
  }

  async getSymbol(contractAddress) {
    const provider = this.getProvider();
    const readOnlyContract = new ethers.Contract(contractAddress, this.erc20_ABI, provider);
    const value = await readOnlyContract.symbol().catch((e) => {
      console.log(e);
    });
    return value;
  }

  async getName(contractAddress) {
    const provider = this.getProvider();
    const readOnlyContract = new ethers.Contract(contractAddress, this.erc20_ABI, provider);
    const value = await readOnlyContract.name().catch((e) => {
      console.log(e);
    });
    return value;
  }

  async getDecimals(contractAddress) {
    const provider = this.getProvider();
    const readOnlyContract = new ethers.Contract(contractAddress, this.erc20_ABI, provider);
    const value = await readOnlyContract.decimals().catch((e) => {
      console.log(e);
    });
    return value;
  }

  async getTotalSupply(contractAddress, decimals) {
    const provider = this.getProvider();
    const readOnlyContract = new ethers.Contract(contractAddress, this.erc20_ABI, provider);
    const value = await readOnlyContract.totalSupply().catch((e) => {
      console.log(e);
    });
    return value.div(10 ** decimals).toNumber();
  }

  async getBurnt(contractAddress, decimals) {
    const provider = this.getProvider();
    const readOnlyContract = new ethers.Contract(contractAddress, this.erc20_ABI, provider);

    const [burntOne, burntTwo] = await Promise.all([
      readOnlyContract.balanceOf("0x0000000000000000000000000000000000000000"),
      readOnlyContract.balanceOf("0x000000000000000000000000000000000000dead"),
    ]).catch((e) => {
      console.log(e);
    });
    return burntOne
      .div(10 ** decimals)
      .add(burntTwo.div(10 ** decimals))
      .toNumber();
  }

  async getTokenPriceBnb(liquidityPairAddress, tokenDecimals) {
    const bnbAddr = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";

    try {
      const provider = this.getProvider();
      const readOnlyContract = new ethers.Contract(liquidityPairAddress, this.pcs_pair_ABI, provider);
      const token0Addr = await readOnlyContract.token0().catch((e) => {
        console.log(e);
      });
      const token1Addr = await readOnlyContract.token1().catch((e) => {
        console.log(e);
      });
      const reserves = await readOnlyContract.getReserves().catch((e) => {
        console.log(e);
      });
      if (!reserves) {
        return;
      }
      let reserve0 = reserves[0];
      let reserve1 = reserves[1];

      if (token0Addr === bnbAddr) {
        reserve0 = reserve0
          .div(10 ** 9)
          .div(10 ** 9)
          .toNumber();
        reserve1 = reserve1.div(10 ** parseInt(tokenDecimals)).toNumber();
        const price = reserve0 / parseFloat(reserve1);
        return [price, price * 2.0 * reserve1];
      } else {
        reserve1 = reserve1
          .div(10 ** 9)
          .div(10 ** 9)
          .toNumber();

        reserve0 = reserve0.div(10 ** parseInt(tokenDecimals)).toNumber();
        const price = reserve1 / parseFloat(reserve0);
        return [price, price * 2.0 * reserve0];
      }
    } catch (e) {
      console.log(e);
    }
  }

  async getBnbPriceUsd() {
    const dataResp = await axios.get("/api/bnb").catch((e) => {
      console.log(e);
    });
    const { data } = await dataResp;
    const { bnbUsd } = data;
    return bnbUsd;
  }

  async getLiquidityPairAddress(contractAddress) {
    const provider = this.getProvider();
    const readOnlyContract = new ethers.Contract(contractAddress, this.erc20_ABI, provider);
    const value = await readOnlyContract.uniswapV2Pair().catch((e) => {
      console.log(e);
    });
    return value;
  }

  async getBccAmount() {
    if (!this.isConnected()) {
      return;
    }
    const provider = await this.getProvider();

    const contractAddress = "0xa397233a0c08052df7569b089864afeb7dc7f8b0";
    const readOnlyContract = new ethers.Contract(contractAddress, this.ABI, provider);
    let value = await readOnlyContract.balanceOf(this.getAddress()).catch((e) => {
      console.log(e);
    });
    if (value) {
      value = value.div(10 ** 9).toNumber();
    }
    return value;
  }
}

export default Chain;
