import Wallet from "@store/actions/wallet";
import { useEffect } from "react";

const BackgroundTask = () => {
  const priceInterval = 10 * 1000;

  const wallet = new Wallet();

  useEffect(() => {
    let timer;
    const work = () => {
      try {
        wallet.setBccAmount();
        wallet.setBccInfos();
      } catch (e) {
        console.log("error fetching bcc info", e);
      }
      timer = setInterval(() => {
        try {
          wallet.setBccAmount();
          wallet.setBccInfos();
        } catch (e) {
          console.log("error fetching bcc info", e);
        }
      }, priceInterval);
    };
    work();
    return () => clearInterval(timer);
  }, []);

  return <></>;
};

export default BackgroundTask;
