import Chain from "@data/chain";
import axios from "axios";
import { ethers } from "ethers";
import { useDispatch, useSelector } from "react-redux";

// ** Handle User Login
const handleConnect = (data) => {
  return (dispatch) => {
    dispatch({
      type: "CONNECT",
      data,
    });

    localStorage.setItem("wallet", JSON.stringify(data));
  };
};

const handleTokens = (data, address) => {
  return (dispatch) => {
    dispatch({
      type: "SET_TOKENS",
      data,
    });

    const tokensKey = `tokens_${address}`;
    localStorage.setItem(tokensKey, JSON.stringify(data));
  };
};

const handleBalances = (data, address) => {
  return (dispatch) => {
    dispatch({
      type: "SET_BALANCES",
      data,
    });

    const tokensKey = `balances_${address}`;
    localStorage.setItem(tokensKey, JSON.stringify(data));
  };
};

// ** Handle User Logout
const handleDisconnect = () => {
  return (dispatch) => {
    dispatch({ type: "DISCONNECT" });

    // ** Remove user, accessToken & refreshToken from localStorage
    localStorage.removeItem("wallet");
  };
};

const handleSetData = (type, data) => {
  return (dispatch) => {
    dispatch({
      type,
      data,
    });
  };
};

class Wallet {
  constructor() {
    this.dispatch = useDispatch();
    this.walletStore = useSelector((state) => state.wallet);
    this.provider = null;
  }

  async getProvider() {
    if (this.provider) {
      return this.provider;
    }
    await window.ethereum.enable();
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    this.provider = provider;
    return provider;
  }

  isConnected() {
    return !!this.getAddress();
  }

  async setTokens(addr, skipCache) {
    const address = addr || this.getAddress();
    const tokenKey = `tokens_${address}`;
    let tokens;
    if (!skipCache && tokenKey in localStorage && localStorage[tokenKey]) {
      tokens = JSON.parse(localStorage[tokenKey]);
    } else {
      tokens = await this.getTokens(address);
    }
    if (tokens) {
      await this.dispatch(handleTokens(tokens, address));
    }
  }

  async getTokens(addr) {
    const address = addr || this.getAddress();
    if (!address) {
      console.log("not connected");
      return;
    }
    const dataResp = await axios.get(`/api/address?id=${address}`).catch((e) => {
      console.log(e);
    });
    const { data } = await dataResp;
    return data;
  }

  async setBccInfos() {
    try {
      const data = await this.getBccInfos();
      const { price: bccPrice, bnb_price: bnbPrice, market_cap: marketCap } = data;
      if (bccPrice) {
        await this.dispatch(handleSetData("SET_BCC_PRICE", bccPrice));
      }
      if (bnbPrice) {
        await this.dispatch(handleSetData("SET_BNB_PRICE", bnbPrice));
      }
      if (marketCap) {
        await this.dispatch(handleSetData("SET_BCC_MARKET_CAP", marketCap));
      }
    } catch (e) {
      console.log(e);
    }
  }

  async setBccAmount() {
    try {
      const bccAmount = await this.getBccAmount();
      if (bccAmount) {
        await this.dispatch(handleSetData("SET_BCC_AMOUNT", bccAmount));
      }
    } catch (e) {
      console.log(e);
    }
  }

  async getBccAmount() {
    if (!this.isConnected()) {
      return;
    }
    const provider = await this.getProvider();

    const ABI = ["function symbol() view returns(string memory)", "function balanceOf(address owner) view returns (uint256)"];
    const contractAddress = "0xa397233a0c08052df7569b089864afeb7dc7f8b0";
    const readOnlyContract = new ethers.Contract(contractAddress, ABI, provider);
    let value = await readOnlyContract.balanceOf(this.getAddress()).catch((e) => {
      console.log(e);
    });
    if (value) {
      value = value.div(10 ** 9).toNumber();
    }
    return value;
  }

  getBccPrice() {
    return this.walletStore.bccPrice;
  }

  async getBccInfos() {
    const contractAddress = "0xa397233a0c08052df7569b089864afeb7dc7f8b0";
    const chain = new Chain();
    const stats = await chain.getStats(contractAddress);
    const bnbPriceUsd = await chain.getBnbPriceUsd();

    const [symbol, totalSupply, burntPercent, liquidityPairAddress, tokenPriceUsd, marketCapUsd, liquidityTotalUsd, percentLiquidityBacking] = stats;
    return { price: tokenPriceUsd, bnb_price: bnbPriceUsd, market_cap: marketCapUsd };
  }

  getAddress() {
    return this.walletStore.address;
  }

  addressShort() {
    const toTake = 7;
    const addr = this.getAddress();
    if (!addr || addr.length < toTake) {
      return "";
    }
    return `${addr.substr(0, toTake)}...`;
  }

  async connect() {
    const provider = await this.getProvider();
    const signer = provider.getSigner();
    const addr = await signer.getAddress();
    const wallet = { address: addr };
    this.dispatch(handleConnect(wallet));
    await this.setTokens(addr);
  }

  disconnect() {
    this.dispatch(handleDisconnect());
  }
}

export default Wallet;
