const initialState = {
  data: [],
  params: {},
};

const apeReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_DATA":
      return {
        ...state,
        data: action.data,
        params: action.params,
      };
    default:
      return { ...state };
  }
};
export default apeReducer;
