// **  Initial State
const storeName = "wallet";

const initialState = {
  address: null,
  bccPrice: 0,
  bnbPrice: 0,
  bccMarketCap: 0,
  bccAmount: 0,
  tokens: [],
};

const walletReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CONNECT":
      return {
        ...state,
        address: action.data.address,
      };
    case "SET_BCC_TOTAL_SUPPLY":
      return {
        ...state,
        bccTotalSupply: action.data,
      };
    case "SET_BCC_MARKET_CAP":
      return {
        ...state,
        bccMarketCap: action.data,
      };
    case "SET_BCC_AMOUNT":
      return {
        ...state,
        bccAmount: action.data,
      };
    case "SET_BCC_PRICE":
      return {
        ...state,
        bccPrice: action.data,
      };
    case "SET_BNB_PRICE":
      return {
        ...state,
        bnbPrice: action.data,
      };
    case "SET_TOKENS":
      return {
        ...state,
        tokens: action.data,
      };
    case "DISCONNECT":
      const obj = { ...action };
      delete obj.type;
      return { ...state, address: null, ...obj };
    default:
      return state;
  }
};

export default walletReducer;
