import mock from "../mock";
import { paginateArray, sortCompare } from "../utils";

const data = {
  invoices: [
    {
      id: "27389470989472398423",
      executedDate: "13 Dec 2019",
      token: {
        name: "BigChungusCoin",
        symbol: "BCC",
      },
      service: "Software Development",
      total: 3428,
      avatar: "",
      invoiceStatus: "Executed",
      balance: "$724",
      dueDate: "23 Apr 2019",
    },
    {
      id: "27389472389jkql2398423",
      executedDate: "17 Jul 2019",
      token: {
        name: "BigChungusCoin",
        symbol: "BCC",
      },
      service: "UI/UX Design & Development",
      total: 5219,
      avatar: require("@src/assets/images/avatars/10-small.png").default,
      invoiceStatus: "Pending",
      balance: 0,
      dueDate: "15 Dec 2019",
    },
    {
      id: "27389472s9472398423",
      executedDate: "19 Oct 2019",
      token: {
        name: "BigChungusCoin",
        symbol: "BCC",
      },
      service: "Unlimited Extended License",
      total: 3719,
      avatar: require("@src/assets/images/avatars/1-small.png").default,
      invoiceStatus: "Executed",
      balance: 0,
      dueDate: "03 Nov 2019",
    },
    {
      id: "2778472389472398423",
      executedDate: "06 Mar 2020",
      token: {
        name: "BigChungusCoin",
        symbol: "BCC",
      },
      service: "Software Development",
      total: 4749,
      avatar: require("@src/assets/images/avatars/9-small.png").default,
      invoiceStatus: "Canceled",
      balance: 0,
      dueDate: "11 Feb 2020",
    },
  ],
};

// ------------------------------------------------
// GET: Return Invoice List
// ------------------------------------------------
mock.onGet("/apps/invoice/invoices").reply((config) => {
  // eslint-disable-next-line object-curly-newline
  const { q = "", perPage = 10, page = 1, status = null } = config;
  /* eslint-enable */

  const queryLowered = q.toLowerCase();
  const filteredData = data.invoices
    .filter(
      (invoice) =>
        /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
        invoice.token.symbol.toLowerCase().includes(queryLowered) || invoice.token.name.toLowerCase().includes(queryLowered)
    )
    .sort(sortCompare("id"))
    .reverse();
  /* eslint-enable  */

  return [
    200,
    {
      allData: data.invoices,
      invoices: paginateArray(filteredData, perPage, page),
      total: filteredData.length,
    },
  ];
});

// ------------------------------------------------
// GET: Return Single Invoice
// ------------------------------------------------
mock.onGet(/\/api\/invoice\/invoices\/\d+/).reply((config) => {
  // // Get event id from URL
  const invoiceId = Number(config.url.substring(config.url.lastIndexOf("/") + 1));

  const invoiceIndex = data.invoices.findIndex((e) => e.id === invoiceId);
  const responseData = {
    invoice: data.invoices[invoiceIndex],
    paymentDetails: {
      totalDue: "$12,110.55",
      bankName: "American Bank",
      country: "United States",
      iban: "ETD95476213874685",
      swiftCode: "BR91905",
    },
  };
  return [200, responseData];
});

// ------------------------------------------------
// DELETE: Deletes Invoice
// ------------------------------------------------
mock.onDelete("/apps/invoice/delete").reply((config) => {
  // Get invoice id from URL
  let invoiceId = config.id;

  // Convert Id to number
  invoiceId = Number(invoiceId);

  const invoiceIndex = data.invoices.findIndex((t) => t.id === invoiceId);
  data.invoices.splice(invoiceIndex, 1);

  return [200];
});

// ------------------------------------------------
// GET: Return tokens
// ------------------------------------------------
mock.onGet("/api/invoice/tokens").reply(() => {
  const tokens = data.invoices.map((invoice) => invoice.token);
  return [200, tokens.slice(0, 5)];
});
