// ** Redux, Thunk & Root Reducer Imports
import { applyMiddleware, compose, createStore } from "redux";
import createDebounce from "redux-debounced";
import thunk from "redux-thunk";
import rootReducer from "../reducers/rootReducer";

// ** init middleware
const middleware = [thunk, createDebounce()];

// ** Dev Tools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistedStore = ["wallet"];

const initialStateDefaultMap = {
  wallet: {
    address: null,
    bccPrice: 0,
    bnbPrice: 0,
    bccAmount: 0,
    tokens: [],
    balances: [],
  },
};

let initialState = Object.assign(
  {},
  ...persistedStore.map((k) => {
    let state = initialStateDefaultMap[k];
    if (k in localStorage) {
      initialState = localStorage.getItem(k);
      if (initialState) {
        state = JSON.parse(initialState);
      }
    }
    return { [k]: state };
  })
);

["tokens", "balances"].forEach((k) => {
  if ("wallet" in initialState && initialState["wallet"]["address"]) {
    const tokensKey = `${k}_${initialState["wallet"]["address"]}`;
    if (tokensKey in localStorage) {
      initialState["wallet"][k] = JSON.parse(localStorage.getItem(tokensKey));
    }
  }
});

// ** Create store
const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(...middleware)));

export { store };
